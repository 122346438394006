/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
export const ErrorStatus = {
  BAD_REQUEST: 400,
  NOT_AUTHORIZED: 401,
  FORBIDDEN_ERROR: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  SERVER_ERROR: 500,
  NETWORK_ERROR: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  LEGAL_REASON_UNAVAILABLE: 451,

};

export const STANDALONE_APPLICATIONS = ['user-admin', 'poc-admin'];
